import React, { useState } from 'react';
import moment from 'moment';
import './App.css';

function App() {
  const [data, setData] = useState([]);
  // Handle the input text state
  const [inputText, setInputText] = useState(
    'https://storage.sardius.media/09622A6042C5397/archives/18e767BC91ed69DEBbC1892fAFA5/static/e9b69F/63AEc67d9Ef8.vtt',
  );
  const [offsetSeconds, setOffsetSeconds] = useState(900);

  const getData = () => {
    fetch(inputText, {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      // mode: 'no-cors', // no-cors, *cors, same-origin
    })
      .then(function (result) {
        return result.text();
      })
      .then(function (result) {
        console.log(result);
        setData(result.split('\n'));
      });
  };
  const formatNumber = (myNumber) => {
    return ('0' + myNumber).slice(-2);
  };
  const formatTimeStamp = (duration) => {
    return `${formatNumber(duration.hours())}:${formatNumber(
      duration.minutes(),
    )}:${formatNumber(duration.seconds())}.${(
      '00' + duration.milliseconds()
    ).slice(-3)}`;
  };
  const formatData = (data) => {
    return data
      .map((line) => {
        if (line.includes(' --> ')) {
          let newLine = line.replace(/(\r\n|\n|\r)/gm, '').split(' ');
          const start = moment
            .duration(newLine[0])
            .subtract(moment.duration(offsetSeconds, 's'));
          const end = moment
            .duration(newLine[2])
            .subtract(moment.duration(offsetSeconds, 's'));
          newLine[0] = formatTimeStamp(start);
          newLine[2] = formatTimeStamp(end);
          return newLine.join(' ');
        } else {
          return line;
        }
      })
      .join('\n');
  };

  const downloadTxtFile = () => {
    const element = document.createElement('a');
    const file = new Blob([formatData(data)], {
      type: 'text/vtt;charset=utf-8',
    });
    element.href = URL.createObjectURL(file);
    element.download = 'newVTTfile.vtt';
    document.body.appendChild(element);
    element.click();
  };

  return (
    <div className="App">
      <header className="App-header">
        <h1>VTT File Information</h1>
        <label>Original VTT File</label>
        <div className="initFile">
          <input
            type="search"
            name="p"
            value={inputText}
            onChange={(e) => setInputText(e.target.value)}
          />
        </div>
        <label>Offest in Seconds</label>
        <div className="initFile">
          <input
            type="text"
            name="p"
            value={offsetSeconds}
            onChange={(e) => setOffsetSeconds(e.target.value)}
          />
        </div>
        <div>
          <button
            className="loadData"
            onClick={() => {
              getData();
            }}
          >
            Load
          </button>
        </div>
      </header>
      {data.length > 0 && (
        <div className="files">
          <div className="file">
            <div className="initFile">
              <h2>Original VTT File</h2>
            </div>
            <div className="code">
              <pre>{data.join('\n')}</pre>
            </div>
          </div>
          <div className="file">
            <div className="initFile">
              <h2>Updated VTT File</h2>
              <button className="download" onClick={downloadTxtFile}>
                Download New VTT
              </button>
            </div>
            <div className="code">
              <pre>{formatData(data)}</pre>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
